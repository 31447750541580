import React, { Component } from 'react';

export default class About extends Component {
   
  render() {
    return (
      <section id="about">
         <div className="row">
            <div className="three columns">
               <img className="profile-pic"  src="images/profilepic.jpg" alt="" />
            </div>

            <div className="eight columns main-col">
               <h2>Om meg</h2>
               <p className="custom-p">Jeg har jobbet 7 år i Red Rock Solutions hvor jeg startet i helpdesken, før turen gikk videre til test/QA, team koordinering og nå utvikler og teknisk ressurs med tett kundekontakt. Arbeidsoppgavene mine inkluderer hele utviklingsløpet av løsningene vi utvikler for våre kunder, fra kravspesifikasjon med kunde, design, utvikling og fremdrift, til ferdig leveranse.<br/><br />
               Underveis har jeg tatt en 2 årig utdannelse på Noroff som Frontend utvikler og behersker både kode og UI/UX design.<br />
                  Som utvikler i Red rock solutions har jeg jobbet mye med Wordpress, React og skreddersydde integrasjoner og løsninger innenfor kollektivtrafikk og transportsektoren.
                  <br /> <br />
                  Ved siden av jobb utvikler jeg en plattform for AI-assistert reiseplanlegging for kollektivtrafikk og mikromobilitet. Her har jeg trent en rekke AI modeller som jeg hoster på min egen AWS infrastruktur.<br />
                  Plattformen har støtte for fritekst reisesøk og har et innholdsrikt kart som viser en rekke tjenester for mikromobilitet og kollektivtrafikk i sanntid.<br />
                  Plattformen er tilgjengelig på <a href="https://movai.no" target="_blank">movai.no</a></p>
               <br/>
               <p className="custom-p">Denne høsten startet jeg også på nye studier i Anvendt Maskinlæring hos Noroff og mine ambisjoner er å kunne i skjæringspunktet mellom mennesket og koden for å skape løsninger som effektiviserer og forenkler livene våre.<br />
               Ta gjerne kontakt for en fagprat eller om konkrete prosjekter, jeg er åpen for nye muligheter.</p>
               <br/>
               <h3 className="custom-p" style={{color: 'white'}}>Nøkkelkunnskaper</h3>
               <p className="custom-p">ReactJS, AI og maskinlæring, Javascript, PHP, HTML/CSS, Wordpress, WooCommerce, REST API, GraphQL, Jira, Mapbox, MySQL, Adobe pakken.</p>
               <br/>
               <h3 className="custom-p" style={{color: 'white'}}>Andre interesseområder</h3>
               <p className="custom-p">Python, OpenCV, Ardunio, Smarte byer, Automatisering, Trening og Friluftsliv.</p>
            </div>
         </div>
      </section>
    );
  }
}