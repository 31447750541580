import React from 'react';
import Header from './Header';
import About from './About';
import Resume from './Resume';
import Portfolio from './Portfolio';
import Hero from './Hero';
function Home() {
    
  return (
    <div>
        <Header />
        <Hero />
        <About />
        <Portfolio />
        <Resume />
    </div>
    
  );
}

export default Home;