import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Navigation = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleScroll = () => {
        if (window.scrollY > 10) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }
    };

    if(isHomePage) {
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    } else {
        setIsScrolled(true);
    }
  }, []);

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const ScrollLink = ({ href, children }) => (
    <a href={href} onClick={(e) => {
      e.preventDefault();
      scrollToSection(href.slice(1));
    }}>
      {children}
    </a>
  );

  const CustomLink = ({ to, anchor, children }) => (
    <Link to={{ pathname: to, state: { anchor } }}>
      {children}
    </Link>
  );

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav id="nav-wrap" className={isScrolled ? 'scrolled' : ''}>
      {/* Mobile menu icon */}
      <div className="mobile-menu-icon" onClick={toggleMobileMenu}>&#9776;</div>
      {/* Desktop menu */}
      <ul id="nav" className="nav">
        <li>
          {isHomePage ? <ScrollLink href="#home">Hjem</ScrollLink> : <CustomLink to="/" anchor="home">Hjem</CustomLink>}
        </li>
        <li>
          {isHomePage ? <ScrollLink href="#about">Om meg</ScrollLink> : <CustomLink to="/" anchor="about">Om meg</CustomLink>}
        </li>
        <li>
          {isHomePage ? <ScrollLink href="#portfolio">Prosjekter</ScrollLink> : <CustomLink to="/" anchor="portfolio">Prosjekter</CustomLink>}
        </li>
        <li>
          {isHomePage ? <ScrollLink href="#resume">CV</ScrollLink> : <CustomLink to="/" anchor="resume">CV</CustomLink>}
        </li>
        <li>
          <CustomLink to="/blogg">
            Blogg
          </CustomLink>
        </li>
      </ul>
      {/* Mobile menu */}
      {mobileMenuOpen && (
  <div className={`mobile-menu ${mobileMenuOpen ? 'open' : ''}`}>
    <ul className="nav">
      <li>
        {isHomePage ? <ScrollLink href="#home">Hjem</ScrollLink> : <CustomLink to="/" anchor="home">Hjem</CustomLink>}
      </li>
      <li>
        {isHomePage ? <ScrollLink href="#about">Om meg</ScrollLink> : <CustomLink to="/" anchor="about">Om meg</CustomLink>}
      </li>
      <li>
        {isHomePage ? <ScrollLink href="#portfolio">Prosjekter</ScrollLink> : <CustomLink to="/" anchor="portfolio">Prosjekter</CustomLink>}
      </li>
      <li>
        {isHomePage ? <ScrollLink href="#resume">CV</ScrollLink> : <CustomLink to="/" anchor="resume">CV</CustomLink>}
      </li>
      <li>
        <CustomLink to="/blogg">
          Blogg
        </CustomLink>
      </li>
    </ul>
  </div>
)}
</nav>
);
};

export default Navigation;