import React from 'react';
import BlogPosts from './BlogPosts';
import Header from './Header';

function Blogg() {
    
  	return (
    	<div>
    		<Header />
    		<div className="blog-list-main">
      			<BlogPosts />
    		</div>
		</div>
  	);
}

export default Blogg;