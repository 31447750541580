import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { a11yDark, xt256 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const CodeHighlighter = ({ code }) => {
  return (
    <SyntaxHighlighter language="javascript" style={a11yDark} showInlineLineNumbers={true} useInlineStyles={true}>
      {code}
    </SyntaxHighlighter>
  );
};

export default CodeHighlighter;