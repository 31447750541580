import React, { Component } from 'react';
import Navigation from './Navigation';

export default class Header extends Component {
  state = {
    isScrolled: false,
  };

  handleScroll = () => {
    const isScrolled = window.scrollY > 0;
    this.setState({ isScrolled });
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    const { isScrolled } = this.state;

    return (
		<React.Fragment>
			<header className={isScrolled ? 'scrolled' : ''}>
				<Navigation />
			</header>
		</React.Fragment>
    );
  }
}
