import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function BlogPosts() {
  	const [posts, setPosts] = useState([]);

  	useEffect(() => {
		const fetchPosts = async () => {
			const response = await axios.get('https://rosander.no/backend/wp-json/wp/v2/posts?_embed');
			setPosts(response.data);
		};

    fetchPosts();
  	}, []);

	const getFeaturedImageUrl = (post) => {
		if (post._embedded['wp:featuredmedia'] && post._embedded['wp:featuredmedia'][0].source_url) {
			return post._embedded['wp:featuredmedia'][0].source_url;
		}
		return null;
	};

	const getPostSlug = (post) => {
		if (post && post.slug) {
			return post.slug;
		}
		return null;
	};

  return (
   
    <div className="blog-posts row">
		<h1>Blogg</h1>
		<p>Etterhvert som prosjektene utvikler seg samler det seg opp mye dokumentasjon og erfaring, så her forsøker jeg å finskrive det litt i tilfelle noen kan ha nytte av det samme.</p>
		
		<div className="posts-grid">
			{posts.map((post) => {
				const featuredImageUrl = getFeaturedImageUrl(post);
				const postSlug = getPostSlug(post);
				return (
					<div key={post.id} className="post-card">
						{featuredImageUrl && <a href={`/blogg/${postSlug}`}> <img src={featuredImageUrl} alt={post.title.rendered} /></a>}
						<h2 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
						<div dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
						{postSlug && (
							<Link to={`/blogg/${postSlug}`} className="read-more-link">Les artikkelen</Link>
						)}
					</div>
				);
			})}
		</div>
    </div>
  );
}

export default BlogPosts;