import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import CodeHighlighter from './CodeHighlighter';
import parse, { domToReact } from 'html-react-parser';
import Navigation from './Navigation';

function SinglePost() {
  const [post, setPost] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const response = await axios.get(`https://rosander.no/backend/wp-json/wp/v2/posts?slug=${slug}&_embed`);
        if (response.data.length > 0) {
          setPost(response.data[0]);
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  const formatDate = (date) => {
    const d = new Date(date);
    const options = {
      weekday: 'long', // Name of the day
      day: 'numeric', // Date
      month: 'long', // Name of the month
    };
    
    return d.toLocaleDateString('nb-NO', options); // 'nb-NO' is the language code for Norwegian Bokmål
  };
  


  const replaceCodeTags = (node) => {
    if (node.type === 'tag' && node.name === 'pre') {
      const codeNode = node.children.find((child) => child.name === 'code');
      if (codeNode) {
        const codeString = codeNode.children[0]?.data || '';
        return <CodeHighlighter code={codeString} />;
      }
    }
  };
  

  return (
    
    <div className="blog-post-main"><Navigation />
      {post ? (
        <div className="blog-post-main-body">

          <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
          <div className="blog-post-main-meta">
            <p>Publisert: {formatDate(post.date)}</p>
            </div><img src={post._embedded['wp:featuredmedia'][0].source_url} alt={post.title.rendered} />
          <div>{parse(post.content.rendered, { replace: replaceCodeTags })}</div>
          <p> Stikkord: {post._embedded['wp:term'][0].map((tag) => tag.name).join(', ')}</p>
        </div>
      ) : (
        <p>Post not found.</p>
      )}
    </div>
  );
}

export default SinglePost;
