import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Blogg from './components/Blogg';
import Home from './components/Home';
import SinglePost from './components/SinglePost';

class App extends Component {
  render() {
    
    return (
      <Router>
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/blogg/:slug" element={<SinglePost />} />
          <Route path="/blogg" element={<Blogg />} />
        </Routes>
      </div>
    </Router>
    );
  }
}

export default App;